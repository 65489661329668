.name{
    margin-top: 30px;
    padding-left: 50px;
    font-size: 60px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.orderstatus button{
   margin-left: 90px;
   margin-top: 20px;
   font-size: 20px;
}

.completedOrders{
    margin-top: 50px;
    margin-left: 120px;
}

.pendingordertable{
    margin-top: 50px;
    margin-left: 120px;
}

.ongoingOrdertable{
    margin-top: 50px;
    margin-left: 120px;
}