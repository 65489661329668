.gallery-container h2 {
    text-align: center;
    color: white;
    margin-top: 30px;
    font-family:Georgia, 'Times New Roman', Times, serif;
    font-size:50px;   
  }

.gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .gallery-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    padding: 50px;
  }
  
  .gallery-item {
    flex: 0 0 30%;
    margin-bottom: 20px;
    cursor: pointer;
    margin-top: 25px;
  }
  
  .gallery-item img {
    width: 100%;
    height: 100%;
    aspect-ratio: 3/2;
  }
  
  .food {
    border: 3px solid #ff6347;
  }
  
  .interior {
    border: 3px solid #4682b4;
  }
  
  .past {
    border: 3px solid #1d6030;
  }
  
  .modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.9);
  }
  
  .modal-content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
    max-height: 80%;
    margin-top: 70px;
  }
  
  .modal.open {
    display: block;
  }  