/* FeedbackCarousel.css */

.feedback-carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .feedback-carousel button {
    background-color: #ff6600; /* Orange */
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1.2rem;
    margin: 0 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .feedback-carousel button:hover {
    background-color: #e65c00; /* Darker shade of orange on hover */
  }
  
  .feedback-content {
    text-align: center;
    padding: 20px;
    border: 2px solid #ff6600; /* Orange border */
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff; /* White background */
    flex: 1;
  }
  
  .feedback-content p:first-child {
    margin-bottom: 10px;
    font-size: 1.2rem;
  }
  
  .feedback-content p:last-child {
    font-style: italic;
    color: #555;
    font-size: 1rem;
  }
  