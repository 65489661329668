.profile-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  
  .profile-details p {
    margin-bottom: 50px;
    align-items: center; 
  }
  
  .profile-details p strong {
    font-weight: bold;
    margin-left: 50px;  
  }

  .profile-details p .dott {
    font-weight: bold;
    margin-left: 30px;
    justify-self: start; 
  }

  .userprofile{
    font-size: 70px;
  }

  .name{
   font-size: 30px;
   margin-left: 0px;
   
  }

  .email{
    font-size: 30px;
    margin-left: 50px;
    margin-top: 35px;

   }

   .mobile{
    font-size: 30px;
    margin-left: 50px;
    margin-top: 35px;

   }

   .address{
    font-size: 30px;
    margin-left: 50px;
    margin-top: 35px;

   }

   .status{
    font-size: 30px;
    margin-left: 50px;
    margin-top: 35px;
   }

   
  
   .userprofile_background {
    background-image: url(../../assets/userprofile_background.jpg);
  /* Adjust the last value (0.5) for opacity */
    background-blend-mode: overlay; /* Apply the overlay blend mode to mix the background color with the image */
    background-size: cover;
    height: 900px;
   
   
} 

.profile-details-container {
  border: 3px solid #0e0202; /* Border style */
  padding: 20px; /* Padding around the profile details */
  border-radius: 10px; /* Rounded corners */
}


  
