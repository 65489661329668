/* EmployeeLeaves.css */

.employee-leaves-container {
  background-image: url('../../../assets/Img_leave.jpeg'); /* Replace 'your-background-image-url.jpg' with your image URL */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.center-button {
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

.apply-leaves-form {
  /* Add styles for ApplyLeaves component here if needed */
}
