.outer-container15 {
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    height: 100vh; /* Set the height of the container to full viewport height */
  }
  
  .attendance {
    width: 500px; /* Set the width of the form */
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .center-heading {
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    font-size: 20px;
  }
  
  .form-group input,
  .form-group select {
    width: 100%; /* Make input fields and select boxes fill the entire width */
    padding: 10px;
    font-size: 16px;
  }
  
  .error {
    color: red;
  }
  