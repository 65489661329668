footer {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-contact-info,
.footer-social-links,
.footer-opening-hours,
.footer-faq {
  flex: 1;
  margin-right: 20px;
}

.footer-contact-info h3,
.footer-social-links h3,
.footer-opening-hours h3,
.footer-faq h3 {
  margin-bottom: 10px;
}

.footer-contact-info p,
.footer-opening-hours p {
  margin: 5px 0;
}

.footer-social-links ul,
.footer-faq ul {
  list-style: none;
  padding: 0;
}

.footer-social-links ul li,
.footer-faq ul li {
  margin-bottom: 5px;
}

.footer-social-links ul li a,
.footer-faq ul li a {
  color: #fff;
  text-decoration: none;
}

.footer-social-links ul li a:hover,
.footer-faq ul li a:hover {
  text-decoration: underline;
}

.footer-bottom {
  margin-top: 20px;
  text-align: center;
}
