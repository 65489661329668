.mb-4
{
    text-align: center;
    margin-top: 50px;
}

.name1
{
    
    margin-bottom: 10px;
}

.name2
{
    
    margin-bottom: 10px;
}
.name3
{
    
    margin-bottom: 10px;
}

.name4
{
    
    margin-bottom: 10px;
}
.name5
{
    
    margin-bottom: 10px;
}

.name6
{
    
    margin-bottom: 10px;
}

.background{
    background-image: url(../../assets/login.jpg);
  /* Adjust the last value (0.5) for opacity */
    background-blend-mode: overlay; /* Apply the overlay blend mode to mix the background color with the image */
   /*height: 900px;*/

}