.topic{

  
    margin-left: 100px;
    margin-right: 100px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 30px;
    text-shadow: black;


}

.imagemem {
    background-image: url(../../assets/membership_card.jpg);
    background-color: rgba(238, 241, 243, 0.5); /* Adjust the last value (0.5) for opacity */
    background-blend-mode: overlay; /* Apply the overlay blend mode to mix the background color with the image */
    background-size: cover;
   
    background-repeat: no-repeat;
    margin-top: 40px; /* Remove default body margin */
    padding: 0; /* Remove default body padding */
    height: 100vh; /* Ensure the body covers the entire viewport height */
}


